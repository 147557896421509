/*  src/page/main.css  */

.main {
    align-content: center;
    box-sizing: border-box;
    width: 90%;
    justify-content: center;
    padding-bottom: 1em;
    margin-bottom: 3em;
    margin-left: auto;
    margin-right: auto;
    color: #FFF;
}
.summary {
    align-content: center;
    box-sizing: border-box;
    width: 80%;
    display: flex;
    justify-content: center;
    padding: 0 1em 1em;
    margin-left: auto;
    margin-right: auto;
    color: #FFF;
}

.summaryMobile {
    align-content: center;
    box-sizing: border-box;
    width: 80%;
    /*display: flex;*/
    justify-content: center;
    /*padding: 0 1em 1em;*/
    margin-left: auto;
    margin-right: auto;
    color: #FFF;
    display: grid;
    /*height: calc(50vw - 5px);  !*instead playing around with grid gap gap *!*/
    grid-template-columns: 1fr 1fr;
}

/*.summaryMobile div:nth-child(2) {*/
/*    !* bg to show i'm squarred or not ? *!*/
/*    !*background-image: linear-gradient( 45deg, transparent 50%, rgba(0, 0, 0, 0.5) 50%);*!*/

/*    margin: 0 5px 5px 0;  !*instead playing around with grid gap gap *!*/
/*    !*background-color: red;*!*/
/*}*/

.summary > div {
    align-content: center;
    display: flex;
    justify-content: center;
    padding: 0 0.1em 0.1em;
    margin-left: auto;
    margin-right: auto;
}

.summaryMobile > div {
    /*align-content: center;*/
    display: flex;
    /*justify-content: center;*/
    padding: 0 0.1em 0.1em;
    /*margin-left: auto;*/
    /*margin-right: auto;*/
}

.price {
    padding-left: 0.2em;
}

.header {
    justify-content: center;
    text-align: center;
    padding-bottom: 0.5em;
}

.headerMobile {
    justify-content: center;
    text-align: center;
    font-size: 1.3em;
    /*padding-bottom: 0.5em;*/
}

.secondHeader {
    justify-content: center;
    text-align: center;
    padding-bottom: 0.5em;
}

.secondHeaderMobile {
    justify-content: center;
    text-align: center;
    font-size: 1.1em;
    /*padding-bottom: 0.5em;*/
}