/*  src/page/Header.css  */

.Header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 1em;
    background-color: #313335;
    color: #FFF;
}

/* починить кнопку обновить для iphone*/
.Header-Subtitle {
    box-sizing: border-box;
    width: 80%;
    display: flex;
    margin-right: -1em;
    justify-content: center;
    align-items: baseline;
    text-align: center;
    color: #FFF;
}

.HeaderMobile {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    margin-bottom: 0.1em;
    background-color: #313335;
    color: #FFF;
}

.Header-SubtitleMobile {
    box-sizing: border-box;
    width: 80%;
    display: flex;
    font-size: 1.5em;
    margin-right: -1em;
    justify-content: center;
    align-items: baseline;
    text-align: center;
    color: #FFF;
}


.buttons {
    justify-content: center;
    align-content: center;
    display: flex;
    /*padding-top: 1vh;*/
    /*width: 400px;*/
    /*margin: 50px auto;*/
    /*padding: 10px 20px;*/
    text-align: center;
    /*background: white;*/
    /*box-shadow: 0 0 3px #cccccc;*/
}

button {
    display: flex;
    position: relative;
    width: 3em;
    height: 3em;
    justify-content: center;
    align-content: center;
    /*margin: 20px auto;*/
    /*padding: 0;*/
    /*font-size: 20px;*/
    /*text-align: center;*/
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    /*text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);*/
    /*transition: transform 0.4s ease-in-out;*/
}

button:active {
    top: 1px;
    box-shadow: none;
}

.icon {
    margin-left: auto;
    margin-right: auto;
}

.btn-grey {
    display: block;
    background: #2b2b2b;
    border-radius: 50%;
}

.btn-grey .icon {
    width: 1.5em;
    height: 1.5em;
}

.btn-grey:hover .icon {
    transform: rotate(360deg) scale(1.2);
}